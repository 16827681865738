export const Instagram = () => {
  return (
    <svg
      id="Group_1223"
      data-name="Group 1223"
      xmlns="http://www.w3.org/2000/svg"
      width="31.768"
      height="31.768"
      viewBox="0 0 31.768 31.768"
    >
      <g id="Group_1222" data-name="Group 1222" transform="translate(0 0)">
        <path
          id="Path_2093"
          data-name="Path 2093"
          d="M1513.49,769.559c4.241,0,4.744.016,6.419.093a8.789,8.789,0,0,1,2.95.547,5.262,5.262,0,0,1,3.014,3.014,8.781,8.781,0,0,1,.547,2.949c.076,1.675.093,2.177.093,6.419s-.016,4.743-.093,6.418a8.783,8.783,0,0,1-.547,2.95,5.262,5.262,0,0,1-3.014,3.015,8.793,8.793,0,0,1-2.95.547c-1.675.077-2.177.093-6.419.093s-4.744-.016-6.418-.093a8.791,8.791,0,0,1-2.95-.547,5.26,5.26,0,0,1-3.015-3.015,8.794,8.794,0,0,1-.547-2.95c-.077-1.675-.093-2.177-.093-6.418s.016-4.743.093-6.419a8.793,8.793,0,0,1,.547-2.949,5.26,5.26,0,0,1,3.015-3.014,8.787,8.787,0,0,1,2.95-.547c1.674-.076,2.177-.093,6.418-.093m0-2.862c-4.313,0-4.854.018-6.549.1a11.667,11.667,0,0,0-3.856.739,8.12,8.12,0,0,0-4.645,4.646,11.65,11.65,0,0,0-.738,3.856c-.077,1.694-.1,2.235-.1,6.549s.018,4.854.1,6.548a11.653,11.653,0,0,0,.738,3.856,8.119,8.119,0,0,0,4.645,4.646,11.665,11.665,0,0,0,3.856.738c1.694.077,2.235.1,6.549.1s4.855-.019,6.549-.1a11.659,11.659,0,0,0,3.855-.738,8.12,8.12,0,0,0,4.646-4.646,11.658,11.658,0,0,0,.739-3.856c.077-1.694.1-2.235.1-6.548s-.018-4.855-.1-6.549a11.655,11.655,0,0,0-.739-3.856,8.121,8.121,0,0,0-4.646-4.646,11.661,11.661,0,0,0-3.855-.739c-1.694-.077-2.235-.1-6.549-.1Z"
          transform="translate(-1497.606 -766.697)"
          fill="#fff"
        />
        <path
          id="Path_2094"
          data-name="Path 2094"
          d="M1526.438,787.372a8.157,8.157,0,1,0,8.157,8.157A8.157,8.157,0,0,0,1526.438,787.372Zm0,13.451a5.295,5.295,0,1,1,5.295-5.294A5.294,5.294,0,0,1,1526.438,800.823Z"
          transform="translate(-1510.555 -779.645)"
          fill="#fff"
        />
        <circle
          id="Ellipse_46"
          data-name="Ellipse 46"
          cx="1.906"
          cy="1.906"
          r="1.906"
          transform="translate(22.457 5.499)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
