export const MeetDeadlines = () => {
  return (
    <>
      <div className="bg-DeadLineBg bg-no-repeat bg-cover w-full lg:h-screen flex justify-center items-center xxs:h-auto">
        <div className="flex justify-center">
          <div className="2xl:w-[60%] md:w-[82%] bg-gradient-to-r from-clientfeedbackgradient2 to-corporateBg text-white m-auto 2xl:p-24 lg:p-12 xxs:p-6 md:my-8 md:mx-0 opacity-95	">
            <h1 className="font-black lg:text-[40px] lg:leading-[48px] font-lato xs:text-[20px] xxs:text-[16px]">
              Meet Deadlines and Keep our Word
            </h1>

            <p className="lg:text-lg lg:leading-[27px] md:text-center xxs:text-justify font-lato xxs:text-sm xxs:leading-5 mb-0 py-4">
              Deadlines need to be met in order to avoid losing customer
              confidence. The customer waits as many days as needed if this was
              agreed upon earlier, but if the product delays for a few days this
              is already a reason to lose confidence and not make another
              purchase in the future. ORIENT COMPANY handles high value medical
              consignments warrant total oversight. It’s a simple idea in a
              complex environment. From leaving the storage location up to the
              installation point, we and our client need assurance that delivery
              is secure and on-schedule.. For a lot of device manufacturers and
              recipients, the benefit of a third party handling logistical
              intricacies in full including site surveys, assessing the product,
              gauging the risk and responding with the method does not require
              much justification.
              <br /> Medical transportation isn’t always about the vast and
              complex though. Small and medium- sized devices are part of the
              same set of resources and efficiency, and knowing how to get
              equipment swiftly up and running can be just as valuable to our
              clients who already have plenty to be getting on with / without
              worrying about deliveries and installation.
            </p>
          </div>
        </div>
      </div>
      <div className="md:pt-28 2xl:w-[75%] xl:w-[90%] lg:w-[65%] md:w-[85%] xxs:w-[90%] m-auto xxs:pt-16 ">
        <h1 className="font-black md:text-[40px] md:leading-[48px] font-lato xs:text-[30px] xs:leading-[50px] xxs:text-[25px]">
          Why safety matters
        </h1>
        <p className="md:text-lg md:leading-[27px] font-lato font-medium text-textLight md:text-center xxs:text-justify 2xl:w-full m-auto xxs:w-full xxs:leading-[24px]">
          At its heart, an investment in worker safety is just the right thing
          to do. However, it also creates significant financial value for your
          company.
          <br /> Many safety and compliance issues happen because employers try
          to cut corners. In an effort to keep expenses down, they take
          shortcuts that put workers
          <br /> at risk. These actions are what the old saying refers to as
          “penny wise and pound foolish.” They save a few dollars today, but
          they expose you to millions of
          <br /> dollars in future liabilities. Smart business owners know that
          a safe working environment is crucial to creating a long-lasting
          business. Spending a few
          <br /> thousand dollars on a safety improvement today is nothing
          compared to paying millions in damages to an injured worker
          <br /> tomorrow. When you consider working with a safety compliance
          company like National PEO, think of it as an investment in your
          business future. Create a<br /> company that’s built to last by making
          safety your top priority.
        </p>
      </div>
    </>
  );
};
