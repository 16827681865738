import {
  AboutCompany,
  Awards,
  Brands,
  CeoMessage,
  FeedBack,
  Footer,
  HomeBanner,
  ProductsAndServices,
} from "../../Components";

export const Home = () => {
  return (
    <>
      <HomeBanner />
      <AboutCompany />
      <Awards />
      <ProductsAndServices />
      <CeoMessage />
      <FeedBack />
      <Brands />

      {/* <OurClients /> */}
      <Footer />
    </>
  );
};
