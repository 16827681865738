import React from "react";

export const CeoMessage = () => {
  return (
    <div className="lg:w-4/5 md:w-5/6 xxs:w-11/12 m-auto flex items-center font-lato sm:mb-20 xxs:mb-0 xxs:flex-col sm:flex-row text-left">
      <div className="sm:w-4/12 xxs:w-full">
        <h2 className="2xl:p-12 xl:p-4 lg:p-4 md:p-0 xxs:p-0 sm:text-5xl xxs:text-3xl sm:!leading-[4rem] xxs:!leading-[3rem]">
          Message from our CEO
        </h2>
      </div>
      <div className="sm:w-8/12 xxs:w-full border-l-2 border-solid border-primary ">
        <p className="2xl:p-20 xl:p-8 lg:p-16 md:p-8 sm:p-8 xxs:p-4 !leading-6 sm:text-lg xxs:text-sm">
          "Gratitude turns what we have into enough, and more. It turns denial
          into acceptance, chaos into order, confusion into clarity...it makes
          sense of our past, brings peace for today, and creates a vision for
          tomorrow."
          <br></br>
          <br></br>
          "Each client is a partnership, a conversation. You are only going to
          be able to go as far as they wish to go, in a way. That makes all the
          difference.” We at Orient Company greatly value your trust and
          confidence and sincerely appreciate your loyalty to our business. Your
          business is ours’ to serve.
          <br></br>
          <br></br>
          <span className=" font-semibold	!leading-6">
            Mr Amit Bedi (CEO & Partner, Orient Company){" "}
          </span>
        </p>
      </div>
    </div>
  );
};
