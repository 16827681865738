import { aboutBackground } from "../../assets";
import { Banner, Footer } from "../../Components";
import { AboutCompany, MainDisciplines } from "../../Container";

export const About = () => {
  return (
    <>
      <Banner
        className="about-banner"
        gradient="bg-bannerBgColor"
        backgroundImage={aboutBackground}
        title="About Us"
        discripation="Teamwork divides the task and multiplies the success"
      />
      <AboutCompany />
      <MainDisciplines />
      {/* <TeamMember /> */}
      {/* <AboutClients /> */}

      <Footer />
    </>
  );
};
