import { useFormik } from "formik";
import { Email } from "../../constant/SMTP";
import { toast } from "react-toastify";
import { emailFunction, emailToPerson } from "../../constant";
import { env } from "../../env.js";

export const DetailsForm = () => {
  const submitHandler = (e) => {};
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    } else if (values.name.length > 20) {
      errors.name = "Must be 15 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.phone_number) {
      errors.phone_number = "Required";
    }
    if (!values.message) {
      errors.message = "Required";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      Email.send({
        Host: env.REACT_APP_SMTP_HOST,
        Username: env.REACT_APP_SMTP_HOST_USER,
        Password: env.REACT_APP_SMTP_HOST_PASS,
        To: values.email,
        From: env.REACT_APP_SMTP_HOST_USER,
        Subject: "Thank you",
        Body: emailToPerson(values),
      })
        .then((response) => {
          toast.success("Email sent successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {
          toast.error("Failed to send email!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      Email.send({
        Host: env.REACT_APP_SMTP_HOST,
        Username: env.REACT_APP_SMTP_HOST_USER,
        Password: env.REACT_APP_SMTP_HOST_PASS,
        To: env.REACT_APP_SMTP_COMPANY_EMAIL,
        From: env.REACT_APP_SMTP_HOST_USER,
        Subject: "New Query Received!",
        Body: emailFunction(values),
      });

      submitHandler();

      formik.handleReset();
    },
  });

  return (
    <>
      <div className="dropdown-form  px-0 xxs:py-0 ">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-4  xxs:w-full xxs:p-0  m-auto "
        >
          <div className="focus-visible:outline-none md:flex md:flex-nowrap xxs:gap-4 xxs:flex xxs:flex-wrap">
            <input
              type="text"
              placeholder="Full Name*"
              onChange={formik.handleChange}
              value={formik.values.name}
              name="name"
              className={
                "focus-visible:outline-clientfeedbackgradient1 focus:outline-clientfeedbackgradient1 focus-within:clientfeedbackgradient1  border-none  md:text-base font-lato font-medium md:leading-5 rounded  md:w-2/4 px-3 xl:py-5  placeholder-footerbgColor xxs:py-4 xxs:w-full " +
                `${
                  formik.touched.name && formik.errors.name
                    ? "career-input-error"
                    : " "
                }`
              }
            />
            <input
              type="email"
              placeholder="Email Address*"
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
              className={
                "focus-visible:outline-clientfeedbackgradient1 focus:outline-clientfeedbackgradient1 focus-within:clientfeedbackgradient1 md:text-base font-lato font-medium md:leading-5 rounded  md:w-2/4 px-3 xl:py-5  placeholder-footerbgColor xxs:py-4 xxs:w-full " +
                `${
                  formik.touched.email && formik.errors.email
                    ? "career-input-error"
                    : " "
                }`
              }
            />
          </div>
          <input
            type="number"
            placeholder="Phone*"
            onChange={formik.handleChange}
            value={formik.values.phone_number}
            name="phone_number"
            className={
              "focus-visible:outline-clientfeedbackgradient1 focus:outline-clientfeedbackgradient1  focus-within:clientfeedbackgradient1   md:text-base md:leading-5 font-medium font-lato  rounded  px-3 xl:py-5  placeholder-footerbgColor xxs:py-4 xxs:w-full " +
              `${
                formik.touched.phone_number && formik.errors.phone_number
                  ? "career-input-error"
                  : " "
              }`
            }
          />

          <textarea
            name="message"
            placeholder="Message*"
            onChange={formik.handleChange}
            value={formik.values.message}
            className="focus-visible:outline-clientfeedbackgradient1 focus:outline-clientfeedbackgradient1 focus-within:clientfeedbackgradient1  md:text-base font-lato md:leading-5  font-medium py-4 px-3 w-full xl:h-32 placeholder-footerbgColor rounded resize-none xxs:h-24"
          />
          <div className="">
            <button
              type="submit"
              className={
                "font-semibold font-lato md:leading-5 md:text-base bg-primary md:py-5 m-auto text-white flex justify-center rounded-sm xxs:py-4 md:w-48 xxs:w-40" +
                (!formik.isValid || !formik.dirty
                  ? "cursor-not-allowed opacity-80 grayscale"
                  : "")
              }
              disabled={!formik.isValid || !formik.dirty}
            >
              Send Now
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
