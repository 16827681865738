import { ProductsAndServicesData } from "../../constant";

export const ProductsAndServices = () => {
  return (
    <>
      <div className="xxs:w-11/12  lg:w-10/12 xl:w-3/4 m-auto h-full xxs:py-8 md:my-16 ">
        <div>
          <h1 className="xxs:text-2xl md:text-4xl xl:text-[40px] xl:leading-[48px] text-black font-black font-lato md:text-center xxs:text-left">
            Products & Services
          </h1>
        </div>
        <div className="flex flex-wrap justify-center gap-8 xxs:pt-8 md:py-16">
          {ProductsAndServicesData.map((item, index) => {
            return (
              <div
                className="bg-lightWhite  md:w-[45%] xl:w-[30%] zoom-effect-container"
                key={index}
              >
                <div className="w-full h-[250px] image-card">
                  <img
                    src={item.image}
                    alt={item.image}
                    className="w-full h-full object-cover card-image"
                  />
                </div>
                <div className="flex flex-col items-start px-4 py-6">
                  <h3 className="text-lg leading-6 font-semibold font-lato py-3 px-0">
                    {item.title}
                  </h3>
                  <p className="w-full text-start text-sm leading-4 font-medium font-lato">
                    {item.about}
                  </p>
                  {/* <Link
                    to={item.link}
                    className="flex flex-row items-center justify-center text-primary"
                  >
                    <p className="m-0 text-lg leading-6">View More</p>
                    <div className="flex w-5 h-auto ml-2">
                      <img src={RightArrow} alt={RightArrow} />
                    </div>
                  </Link> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
