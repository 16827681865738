import { Footer } from "../../Components";
import { EmbroideryBackground } from "../../assets";
import { Banner } from "../../Components";
import { EmbroideryOrientGroup } from "../../Container/EmbroideryOrientGroup";

export const EmbroideryDivision = () => {
  return (
    <div id="embroidery">
      <Banner
        gradient="bg-bannerBgColor"
        backgroundImage={EmbroideryBackground}
        // title="EMBROIDERY MONOGRAMMING SCREEN PRINTING"
        title="Embroidery Monogramming Screen Printing "
        discripation="Business Promotional Items"
      />
      <EmbroideryOrientGroup />
      <Footer />
    </div>
  );
};
