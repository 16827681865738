import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { ImagesFeedback } from "../../constant";
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 0 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 799, min: 500 },
    items: 3,
  },
  smmobile: {
    breakpoint: { max: 499, min: 0 },
    items: 1,
  },
};

const renderImage = () => {
  return ImagesFeedback.map((item, index) => {
    return (
      <div
        key={index}
        className="w-28 h-28 flex justify-center items-center m-auto"
      >
        <div className="w-36 h-auto">
          <img src={item?.image} alt={item?.name} className="w-full h-full" />
        </div>
      </div>
    );
  });
};

export const Brands = () => {
  const CustomDot = ({ onClick, active }) => {
    return (
      <div className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <div
          className={
            active
              ? " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-corporateBg border-2 border-solid border-corporateBg"
              : " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-transparent border-2 border-solid border-black"
          }
        ></div>
      </div>
    );
  };

  return (
    <>
      <div className="xl:w-3/4 xxs:w-11/12 mx-auto xxs:mt-6 md:mt-12">
        <Carousel
          responsive={responsive}
          showDots={true}
          // infinite={true}
          autoPlay={true}
          customDot={<CustomDot />}
          autoPlaySpeed={1500}
          removeArrowOnDeviceType={[
            "desktop",
            "superLargeDesktop",
            "tablet",
            "mobile",
            "smmobile",
          ]}
        >
          {renderImage()}
        </Carousel>
      </div>
    </>
  );
};
