import { Shape, dmc1, dmc2, dmc3, dmc4, dmcBannerNew } from "../../assets";
import { Dmcdetails } from "../../constant";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 799, min: 500 },
    items: 2,
  },
  smmobile: {
    breakpoint: { max: 499, min: 0 },
    items: 1,
  },
};
const ClothesCollections = [
  {
    image: dmc1,
  },
  {
    image: dmc2,
  },
  {
    image: dmc3,
  },
  {
    image: dmc4,
  },
];
const renderImage = () => {
  return ClothesCollections.map((instance, index) => {
    return (
      <div className="w-11/12 m-auto h-full xxs:mt-[-2rem] " key={index}>
        <img
          src={instance.image}
          alt="ClothesCollections"
          className="w-full h-full"
        />
      </div>
    );
  });
};
export const DmcOrientGroup = () => {
  const CustomDot = ({ onClick, active }) => {
    return (
      <div className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <div
          className={
            active
              ? " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-corporateBg border-2 mb-4 border-solid border-corporateBg"
              : " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-transparent border-2 mb-4 border-solid border-black"
          }
        ></div>
      </div>
    );
  };
  return (
    <div className="xxs:w-11/12 lg:w-10/12 2xl:w-[62%] xl:w-3/4 m-auto md:py-32 xxs:py-12">
      <p className=" text-center  m-auto py-4  text-textLight xxs:w-full">
        <b className="text-black md:text-[22px] md:leading-8">
          ORIENT COMPANY{" "}
        </b>
        <span className="md:text-lg md:leading-8 xxs:leading-7">
          started up initially with an idea to establish a Destination
          Management Company of which is totally dedicated in promoting tourism
          into Kuwait, thus sparked into a opening up of ORIENT COMPANY DMC –
          Kuwait”
        </span>
      </p>
      <figure>
        <div className="w-full imagesContainer ">
          <img
            src={dmcBannerNew}
            alt="TravelImage"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="mt-[-5%]">
          <img src={Shape} alt="shape" />
        </div>
      </figure>
      <div>
        <p className=" text-center  m-auto py-4  text-textLight xxs:w-full">
          <b className="text-black md:text-[22px] md:leading-8">ORIENT DMC </b>
          <span className="md:text-lg md:leading-8 xxs:leading-7">
            offers some of the widest choices of services and products in the
            Kuwait market, ranging from a simple ticket issuance to the
            organization of the most comprehensive Holiday experience and
            professional shore excursions programs and logistics support and
            event management support be it a Camel Race a horse race or a
            business event.
          </span>
          <br></br>
          <br></br>
          <span className="md:text-lg md:leading-8 xxs:leading-7">
            A spirit of Innovation, Teamwork, Client Focus, Integrity and
            Efficiency is commonly shared by a team of Travel professionals
            which Knowledge of the business and expertise have made of the
            ORIENT DMC what it means today at the regional level:
            Professionalism, Choice, Expertise and Service.
          </span>
        </p>
      </div>
      <div className="development-container pt-20">
        {Dmcdetails.map((detail, index) => {
          return (
            <div
              className={
                index % 2 === 0
                  ? "development-wrapper-even"
                  : "development-wrapper-odd"
              }
              key={index}
            >
              <div className="md:w-4/5 xxs:w-full border-b-[16px] border-corporateBg xl:pt-0  xxs:pt-8">
                <img src={detail.img} alt="details" className="w-full h-full" />
              </div>
              <p className="md:w-4/5 xxs:w-full mb-0 text-textLight text-justify font-lato lg:text-lg md:text-base xxs:leading-6 lg:leading-7 font-medium xxs:pt-4">
                {detail.details}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-20">
        <div className="relative">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            showDots={true}
            customDot={<CustomDot />}
            removeArrowOnDeviceType={[
              "mobile",
              "tablet",
              "desktop",
              "superLargeDesktop",
              "smmobile",
            ]}
          >
            {renderImage()}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
