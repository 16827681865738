import React from "react";
import { Arrow } from "../../assets";

const ScrollButton = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <div
      className="fixed bottom-5 right-6 border-solid border border-primary rounded-md  backdrop-blur cursor-pointer	"
      id="scroll_button"
      onClick={goToTop}
    >
      <div className=" bg-transparent flex justify-center items-center py-[23px] px-[18px] m-auto">
        <img src={Arrow} alt="scroll" className="w-[20px] h-[10px]" />
      </div>
    </div>
  );
};
export default ScrollButton;
