export var Email = {
  send: function (a) {
    return new Promise(function (resolve, reject) {
      a.nocache = Math.floor(1e6 * Math.random() + 1);
      a.Action = "Send";
      var t = JSON.stringify(a);

      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://orientgrp.com/common_mail_php.php", true);
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.onload = function () {
        var response = xhr.responseText;
        resolve(response);
      };
      xhr.send(t);
    });
  },
};
