import {
  
  PlusIcon,
  Minus,
} from "../../assets";
import { Tradingdata } from "../../constant";
import { useState } from "react";

export const TradingOrientGroup = () => {
  const [openIndex, setOpenIndex] = useState([]);

  const handleClick = (index) => {
    if (openIndex.includes(index)) {
      setOpenIndex(openIndex.filter((instance) => instance !== index));
    } else {
      setOpenIndex([...openIndex, index]);
    }
  };
  return (
    <>
      <div className="">
        <div className="xxs:w-[90%] lg:w-[85%] 2xl:w-[62%] xl:w-[70%] m-auto md:py-32 xxs:py-12 xxs:text-justify">
          <div>
            {Tradingdata.map((item, index) => {
              return (
                <>
                  <div className="flex justify-between items-start hover:cursor-pointer" key={index}  onClick={() => handleClick(index)}
>
                    <div className="md:w-full xxs:w-[70%]">
                      <h1 className="text-tradingdivisionheading lg:text-2xl lg:leading-7 md:py-3 xxs:py-2 font-bold font-lato xxs:text-base xxs:leading-6">
                        {item.heading}
                      </h1>
                    </div>
                    <img
                      width="20px"
                      height="auto"
                      src={openIndex.includes(index) ? Minus : PlusIcon}
                      // onClick={() => handleClick(index)}
                      alt="add"
                      className="mt-[10px]"
                    />
                  </div>

                  {openIndex.includes(index) && (
                    <div className="flex flex-col text-justify leading-6">
                      <p className="lg:text-xl lg:leading-[40px] font-montserrat text-left xxs:text-sm xxs:leading-8">
                        {item.details}
                      </p>
                      {item?.Brand && (
                        <p className="lg:text-lg font-montserrat font-semibold text-left xxs:text-sm">
                          {item?.Brand}
                        </p>
                      )}
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
