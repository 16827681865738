import { Facebook, Instagram, Linkedin, Orient } from "../../assets";

export const Footer = () => {
  return (
    <>
      <div className="w-full border-t-[10px] border-solid border-primary md:my-28 xxs:my-8">
        <footer className="bg-carbon-fiber w-full text-white ">
          <div className="bg-no-repeat">
            <div className="lg:w-[75%] xxs:w-[90%] m-auto pt-12 ">
              <div className="flex flex-col justify-center ">
                <div className="flex  flex-col justify-center items-center">
                  <div className="2xl:h-28 xxs:h-28">
                    <a href="/">
                      <img
                        src={Orient}
                        className="lg:w-32 lg:h-18 xxs:h-auto xxs:w-36"
                        alt="logo"
                      />
                    </a>
                  </div>
                  <ul className="text-center md:w-[60%] md:text-lg xxs:w-full ">
                    <li>
                      <p className="xxs:text-sm md:text-base md:leading-5 text-white mb-0 font-montserrat ">
                        {" "}
                        Orient Company is committed to the consistent pursuit of
                        excellence and continuous dedication in improving its
                        business to provide clients with products, services that
                        fully comply with their requirements.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="flex flex-col justify-center items-center">
                  <div className="py-4">
                    <h1 className="font-bold font-lato leading-4 text-base  text-white">
                      Contact Us
                    </h1>
                  </div>
                  <ul className="text-center xxs:text-sm font-montserrat">
                    <li>
                      <p>
                        {/* <a
                          href="https://www.mozilla.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-white hover:text-white font-montserrat text-sm leading-5"
                        > */}
                        <span className="text-headingYellow font-montserrat text-sm leading-5">
                          Address:
                        </span>{" "}
                        Al Manakh Commercial Tower, Al Bahrain Street, Block 6,
                        Flr #2, Off #5, Salmiya , Kuwait{/* </a> */}
                      </p>{" "}
                    </li>
                    <ul className="flex justify-center md:gap-4 lg:flex-row  xxs:flex-col xxs:gap-0 text-center">
                      <li>
                        <p>
                          <span className="text-headingYellow font-montserrat text-sm leading-5">
                            C.R.No.
                          </span>
                          455254{" "}
                        </p>{" "}
                      </li>
                      <li>
                        <p>
                          <span className="text-headingYellow font-montserrat text-sm leading-5">
                            Unique Entity ID:{" "}
                          </span>
                          LAUFLLHEHXM{" "}
                        </p>{" "}
                      </li>
                      <li>
                        <p>
                          <span className="text-headingYellow font-montserrat text-sm leading-5">
                            Cage/N Cage{" "}
                          </span>
                          SPMW4{" "}
                        </p>{" "}
                      </li>
                    </ul>
                    <ul className="flex justify-center md:gap-4 lg:flex-row  xxs:flex-col xxs:gap-0 text-center">
                      <li>
                        <a
                          href="mailto:info@Orientgrp.com"
                          className="font-montserrat text-sm leading-5"
                        >
                          <p>
                            <span className="text-headingYellow font-montserrat text-sm leading-5">
                              Email:{" "}
                            </span>
                            procure@orientgrp.com{" "}
                          </p>{" "}
                        </a>
                      </li>
                      <li>
                        <p>
                          <span className="text-headingYellow font-montserrat text-sm leading-5">
                            Whatsapp:
                          </span>

                          {/* <a
                            href="tel:+965-50642420"
                            className="font-montserrat text-sm leading-5"
                          >
                            {" "}
                            +965-50642420
                          </a> */}
                          <a
                            href="https://wa.me/96550642420"
                            className="font-montserrat text-sm leading-5"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            +965-50642420
                          </a>
                        </p>{" "}
                      </li>
                    </ul>
                  </ul>
                  <div className="flex flex-row items-center m-auto  mt-[-1rem]">
                    <ul className=" mb-0 flex items-center ">
                      <li>
                        <div className="my-3">
                          <a
                            href="https://www.facebook.com/Orient-Emb-Division-228986024250663"
                            aria-label="Facebook"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="h-auto w-5 flex">
                              <img src={Facebook} alt="facebook" />
                            </div>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="m-3 insta">
                          <a
                            href="https://www.instagram.com/tatrizkuwait_orientgroup/"
                            aria-label="Instagram"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="h-auto w-5 flex">
                              <Instagram />
                            </div>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="m-3 linked">
                          <a
                            href="https://www.linkedin.com/in/orient-company-526a45258/"
                            aria-label="Linkedin"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="h-auto w-5 flex">
                              <Linkedin />
                            </div>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr className="text-white w-full mt-8" />
              <div className=" font-montserrat w-full flex md:justify-center xxs:justify-start xxs:text-sm  py-4 leading-5 md:flex md:flex-row  md:text-left  md:text-base xxs:flex xxs:flex-col xxs:text-center ">
                {/* <div className="lg:flex xxs:text-center pt-4">
                  <div className="lg:flex xxs:flex ">
                    <span>Copyright</span> &nbsp;
                    <span>
                      <CopyRightIcon />
                    </span>{" "}
                    &nbsp;
                  </div>
                  <a
                    href="/"
                    className="text-white font-bold hover:text-white"
                    aria-label="Madina Holding Company."
                    target="_blank"
                    rel="noreferrer"
                  >
                    Orient Company.
                  </a>
                  &nbsp;
                  <p className="">All rights reserved.</p>
                </div> */}

                <div className="lg:flex xxs:flex xxs:flex-wrap xxs:justify-center">
                  {/* <span>Copyright @ 2022</span>&nbsp; */}
                  <div className="lg:flex xxs:flex ">
                    <span>Copyright</span> &nbsp;
                    <span>&copy;</span>
                    &nbsp;
                  </div>
                  <a
                    href="/"
                    className="text-white font-bold hover:text-white"
                    aria-label="Tamkin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Orient Company
                  </a>
                  &nbsp;
                  <p className="">All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
