import {
  ClothImage8,
  ClothImage6,
  ClothImage7,
  GroupImage1,
  GroupImage2,
  GroupImage3,
  GroupImage4,
  GroupImage6,
  GroupImage5,
  embroideryall,
} from "../../assets";
import { designGoals, difference, groupOfClothes } from "../../constant";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 799, min: 500 },
    items: 1,
  },
  smmobile: {
    breakpoint: { max: 499, min: 0 },
    items: 1,
  },
};

const ClothesCollections = [
  // {
  //   image: ClothImage1,
  // },
  {
    image: ClothImage8,
  },
  {
    image: ClothImage6,
  },
  {
    image: ClothImage7,
  },
  {
    image: ClothImage8,
  },
  {
    image: ClothImage6,
  },
  {
    image: ClothImage7,
  },
  {
    image: GroupImage1,
  },
  {
    image: GroupImage2,
  },
  {
    image: GroupImage3,
  },
  {
    image: GroupImage4,
  },
  {
    image: GroupImage6,
  },
  {
    image: GroupImage5,
  },
  {
    image: embroideryall,
  },
];
const renderImage = () => {
  return ClothesCollections.map((instance, index) => {
    return (
      <div className="w-1/2 m-auto h-full xxs:mt-[-2rem] " key={index}>
        <img
          src={instance.image}
          alt="ClothesCollections"
          className="w-full h-full"
        />
      </div>
    );
  });
};

export const EmbroideryOrientGroup = () => {
  const CustomDot = ({ onClick, active }) => {
    return (
      <div className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <div
          className={
            active
              ? " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-corporateBg border-2 mb-4 border-solid border-corporateBg"
              : " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-transparent border-2 mb-4 border-solid border-black"
          }
        ></div>
      </div>
    );
  };

  return (
    <div className="xxs:w-11/12 lg:w-10/12 2xl:w-[62%] xl:w-3/4 m-auto md:py-12 xxs:py-8">
      <h1 className="text-black font-lato  xs:text-[23px] xxs:leading-[40px] md:text-2xl  md:leading-[40px]">
        CORPORATE GIFT ITEMS & CUSTOMISED UNIFORMS
      </h1>
      <p className="text-textLight py-4 md: w-[95%] xxs:w-full ">
        {/* <span className="md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm">
          We would like to have this opportunity to introduce ourselves as
          Orient Company (OG-Embroidery/Uniforms division). Orient Company is
          committed to the consistent pursuit of excellence and continuous
          dedication in improving its business to provide clients with products,
          services that fully comply with their requirements.
        </span> */}
        <span className="md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato ">
          When you need to customize a personal item like a garment, there are
          several ways to make your mark. For custom uniform work clothing, you
          typically have three choices: monogramming, embroidery, or screen
          printing. All produce great results, but the one that is right for you
          depends on a couple of factors, namely the type of image you want to
          replicate and where you want to place it.
        </span>
        <br></br>
        <br></br>
        <span className="md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato">
          It is possible to replicate everything from basic initials or small
          words to complicated logos or images. Each method has its preferred
          materials and level of design complexity it can handle. Here are the
          details regarding these methods to help you decide the best way to
          personalize your next garment.
        </span>
      </p>
      <div>
        <h1 className="text-start text-black font-lato xs:text-[23px] xxs:leading-[40px] md:text-2xl  md:leading-[40px]">
          WHAT’S THE DIFFERENCE?
        </h1>
        <p className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
          Whether you get a monogram, screen print or embroidery depends on your
          design needs. Each method has its advantages and offers attractive and
          durable customization solutions.
        </p>
        {/* <ul className="text-start"> */}
        {difference.map((item, index) => {
          return (
            <>
              <li className="flex text-start">
                <li className="">
                  <b className="font-bold">{item.title} </b>
                  <span className="md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm text-start font-lato text-textLight">
                    {item.para}
                  </span>
                </li>
              </li>

              {/* <li>{item.para}</li> */}
            </>
          );
        })}
        {/* </ul> */}
        <h1 className="text-start text-black font-lato xs:text-[23px] xxs:leading-[40px] md:text-2xl  md:leading-[40px] pt-6">
          STURDY DESIGNS: MONOGRAMS OR EMBROIDERY
        </h1>
        <p className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
          Business clothes and other cloth-based items like towels take a
          beating in the washer and dryer. Therefore, these items do better with
          a stitched design that is firmly integrated into their textiles.
          Monograms and embroidery suit many cloth-based items, especially if
          you choose high-quality services that use high-quality threads and
          workmanship. The embroidered message on your items can last for years
          with proper care, which includes washing them in the gentle cycle with
          cold water.
        </p>
        <p className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
          Thick materials like towels, canvas, and jeans are also excellent
          mediums for a monogram or embroidery. This method is also possible to
          use on materials of various textures, like fleece jackets and can last
          on synthetic materials as well.
        </p>
        <h1 className="text-start text-black font-lato xs:text-[23px] xxs:leading-[40px] md:text-2xl  md:leading-[40px]">
          SCREEN PRINTING: FAST, ATTRACTIVE AND CONVENIENT
        </h1>
        <p className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
          Screen printing “stamps” designs onto the fabric and other materials.
          When you’re dealing with clothing, it’s important to stick to fabrics
          that can absorb the ink. Natural materials such as cotton, cotton
          blends, and other non-textured fabrics that aren’t sensitive to heat
          are ideal. For example, silk is a sensitive fabric; while is not
          impossible to screen print on it, it’s not the easiest option to
          customize, either. Also, any material that has too much texture, such
          as fleece, cashmere, angora, or chenille, will have difficulty taking
          on-screen print designs.
          <br />
          Screen printing is great for broadcasting ornate, colorful messages on
          clothing and other items in a short amount of time. If you have a
          complicated logo or image, this medium can bring your design vision to
          life. For example, if you need a set of short sleeve basic jersey
          t-shirts featuring a team name for a 5K fun run or your company’s logo
          for a team-building activity, screen printing is a great solution for
          you. When cared for properly, screen printing can last up to five
          years or more before showing any wear or tear.
        </p>
        <h1 className="text-start text-black font-lato xs:text-[23px] xxs:leading-[40px] md:text-2xl  md:leading-[40px]">
          MEET YOUR DESIGN GOALS
        </h1>
        <p className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
          The method you use to personalize your item depends on your design
          needs and the type of item you wish to design. Overall, consider the
          nature of the material, the complexity of the design, and how long you
          want the results to last. Monogramming is great for simple,
          long-lasting messages in stitches, whereas embroidery can produce more
          intricate designs in stitch form as well. Screen printing uses
          materials that adhere to the surface without the help of permanent
          stitching, so it’s important to find the appropriate substrates that
          can display these messages well. It can also work with many types
          of business promotional items.
        </p>
        <p className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
          What would you like to embellish? Try your hand at some custom
          monograms, embroidery, or screen printing. There are also other ways
          to customize your items like hot stamping, engraving, and
          direct-to-garment printing. Whatever you choose, you are guaranteed to
          make a statement.
        </p>

        {designGoals.map((item, index) => {
          return (
            <>
              <li
                className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-xs xs:text-sm  xs:leading-7 font-lato text-textLight"
                key={index}
              >
                {item.goals}
              </li>
            </>
          );
        })}

        <li className="text-start font-bold pt-16">All Promotional Products</li>
        <li className="text-start">
          <b className="text-start font-bold">Personalized Bags </b>
          <span className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
            Backpacks Business Bags Drawstring Bags Duffels & Gym Bags Laptop &
            Tablet Bags Luggage Lunch Totes & CoolersTote Bags
          </span>
        </li>
        <li className="text-start">
          {" "}
          <b className="text-start font-bold">Personalized Drinkware </b>
          <span className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
            Can Coolers Ceramic Mugs Glassware Insulated Tumblers Plastic Cups
            Re-usable Straws & Accessories Travel Mugs & Tumblers Water Bottles
          </span>
        </li>
        <li className="text-start">
          <b className="text-start font-bold">Hats & Visors </b>
          <span className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
            Baseball Caps Beanies Bucket Hats Trucker Hats Visors
          </span>
        </li>
        <li className="text-start">
          <b className="text-start font-bold">Home, Auto & Tools </b>
          <span className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
            Dog & Pet Supplies Food & CandyLiving
          </span>
        </li>
        <li className="text-start">
          <b className="text-start font-bold">Office & Stationery </b>
          <span className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
            Desk Accessories Stationery & Notebook s Stress Relievers & Fidgets
            Writing Instruments
          </span>
        </li>
        <li className="text-start">
          <b className="text-start font-bold">Outdoor & Leisure </b>
          <span className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
            Blankets & Towels Lunch Totes & Coolers Golf Sunglasses Outdoor
            Furniture Outdoor Games Umbrellas
          </span>
        </li>
        <li className="text-start">
          <b className="text-start font-bold">Personal Care & Wellness </b>
          <span className="text-start md:text-lg md:leading-8 xxs:leading-6 xxs:text-sm font-lato text-textLight">
            Face Masks First Aid Sports & Fitness
          </span>
        </li>
      </div>
      <div className="grid md:grid-cols-3 xxs:grid-cols-2 justify-center md:gap-12  md:mt-16 xxs:mt-20 xxs:gap-8">
        {groupOfClothes.map((clothimage, index) => {
          return (
            <>
              <div className="w-11/12 h-full m-auto " key={index}>
                <img
                  src={clothimage.image}
                  alt="clothimage"
                  className="w-full h-full object-contain"
                />
              </div>
            </>
          );
        })}
      </div>
      <div className="mt-20">
        <div className="relative">
          <Carousel
            responsive={responsive}
            // infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            showDots={true}
            customDot={<CustomDot />}
            removeArrowOnDeviceType={[
              "mobile",
              "tablet",
              "desktop",
              "superLargeDesktop",
              "smmobile",
            ]}
          >
            {renderImage()}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
