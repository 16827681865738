import {  LogisticBackground } from "../../assets"
import { Banner, Footer } from "../../Components"
import { LogisticsOrientGroup } from "../../Container/LogisticsOrientGroup"
import { MeetDeadlines } from "../../Container/MeetDeadlines"

export const LogisticsDivision = () => {
    return(
        <div id="logistic">
        <Banner
        gradient="bg-bannerBgColor"
        backgroundImage={ LogisticBackground }
        title="Logistics Division"
        discripation="Choose Possibility in every Direction"
        // id="#embroidery"
      />
      <LogisticsOrientGroup/>
      <MeetDeadlines/>
      <Footer/>
        </div>
    )
}