import {
  About3,
  AboutCard1Image,
  DmcNewImage,
  LogisticNewImage,
  TradingNewImage,
} from "../../assets";
import { Linkarrow } from "../../assets/icon/Linkarrow";
import { HashLink } from "react-router-hash-link";

const aboutImage = [
  {
    image: AboutCard1Image,
    alt: "about1",
    text: "Contracting Division",
    icon: <Linkarrow />,
    link: "contracting-division",
  },

  {
    image: TradingNewImage,
    alt: "about3",
    icon: <Linkarrow />,
    text: "Trading Division",
    link: "trading-division",
  },
  {
    image: About3,
    alt: "about2",
    icon: <Linkarrow />,
    text: "Embroidery Division",
    link: "embroidery-division",
  },
  {
    image: DmcNewImage,
    alt: "about4",
    icon: <Linkarrow />,
    text: "DMC Division",
    link: "DMC-division",
  },
  {
    image: LogisticNewImage,
    alt: "about4",
    icon: <Linkarrow />,
    text: "Logistics Division",
    link: "logistics-division",
  },
];

export const AboutCompany = () => {
  return (
    <>
      <div className="xl:w-3/4 lg:10/12 xxs:w-11/12 lg:my-16 lg:mr-auto xxs:m-auto flex lg:flex-row xxs:flex-col">
        <div className="m-auto  h-full xl:w-3/4 lg:w-full  md:w-[90%] xxs:w-full xxs:mt-8 ">
          <div className="flex flex-wrap justify-center gap-8 ">
            {aboutImage.map((image, index) => {
              return (
                <>
                  <div
                    className="lg:w-[30%] flex flex-col xxs:w-full zoom-effect-container"
                    key={index}
                  >
                    <HashLink
                      to={image.link}
                      smooth={true}
                      className="flex flex-col image-card "
                    >
                      <img
                        src={image.image}
                        alt={image.alt}
                        className="h-full w-full object-cover card-image "
                      />
                    </HashLink>
                    <HashLink
                      to={image.link}
                      smooth={true}
                      className="flex flex-col image-card "
                    >
                      <div className="bg-gradient-to-r from-clientfeedbackgradient2 to-clientfeedbackgradient1 flex justify-center items-center h-[3.2rem] gap-2 hover:w-full h-full] strip-wrapper ">
                        <p className="text-white font-semibold text-lg font-lato leading-5 mb-0">
                          {image.text}
                        </p>
                        <Linkarrow />
                      </div>
                    </HashLink>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
