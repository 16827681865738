import { Fade } from "react-reveal";
import { OperatesData } from "../../constant";

export const MainDisciplines = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-corporateBg to-clientfeedbackgradient2 h-full service-container xl:my-16 lg:my-44">
        <div className=" bg-mainBackground bg-center bg-cover">
          <div className="xl:py-44 xxs:py-28 h-full">
            <Fade left cascade>
              <div className="md:text-center xxs:text-left xxs:w-4/5	xxs:m-auto">
                <h6 className="2xl:text-base 2xl:leading-5 font-lato text-white font-medium lg:pb-4 xxs:pb-0 xl:text-[28px]">
                  MAIN DISCIPLINES
                </h6>
                <h1 className="2xl:text-[40px] 2xl:leading-[48px] font-lato text-white font-black xl:pb-12 xxs:pb-4 xs:text-base xxs:text-sm md:text-[33px] xl:text-[40px]">
                  IN WHICH ORIENT COMPANY OPERATES ARE
                </h1>
              </div>
            </Fade>

            <div className="grid 2xl:grid-cols-3 lg:w-2/3 xxs:w-4/5 xxs:grid-cols-1 md:grid-cols-2	gap-8 m-auto justify-center">
              {OperatesData.map((item, index) => {
                return (
                  <div
                    className="flex flex-col zoom-effect-container"
                    key={index}
                  >
                    <div className="image-card">
                      <img
                        src={item.image}
                        alt={item.image}
                        className="w-full h-full card-image"
                      />
                    </div>
                    <div className="p-4 bg-white  strip-wrapper">
                      <h6 className="text-xl leading-6 text-black font-lato font-bold mb-0">
                        {item.name}
                      </h6>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
