import { Shape, LogisticContent } from "../../assets";
import { ReadMore } from "../../Components";
import { Logisticsdetails } from "../../constant";

export const LogisticsOrientGroup = () => {
  return (
    <div className="xxs:w-11/12 lg:w-10/12 xl:w-3/4 2xl:w-[65%] m-auto md:py-32 xxs:py-12">
      <p className="md:text-center xxs:text-justify text-textLight">
        <span className="md:text-lg md:leading-8 xxs:leading-6">
          We ORIENT COMPANY is gathering information and putting structures in
          place to make sure our logistics function can achieve the three rights
          in the future. More importantly, it seeks to make certain to
          have plans in place to deal with disruptions to our supply and
          transport resources
        </span>
      </p>
      <figure>
        <div className="w-full imagesContainer ">
          <img
            src={LogisticContent}
            alt="TravelImage"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="mt-[-5%]">
          <img src={Shape} alt="shape" />
        </div>
        <div className="md:pt-20 xxs:pt-6" id="check">
          <h1 className="font-black  xl:text-[40px] xl:leading-[48px] font-lato lg:text-[36px] md:text-[28px] xs:text-[15px] xxs:text-[13px]">
            But why do WE need logistics planning services?
          </h1>
          <p className="md:w-[90%] xxs:hidden md:block m-auto xxs:w-full mb-0 text-textLight text-center font-lato lg:text-lg md:text-base xxs:leading-6 lg:leading-7 font-medium xxs:pt-4">
            Every business has some logistics function. No matter what we are
            doing, we need to supply goods, services and/or information to our
            customers, and receive payment in return. ORIENT COMPANY shall be
            sourcing raw materials, parts, or finished products, transporting
            people, units, materials, and keeping everything moving smoothly. To
            stay competitive, we struggle every day to minimise costs, maintain
            and improve customer service levels avoid or mitigate disruptions of
            all kinds and improve the quality of all of our deliverables. The
            challenge here, is that logistics – supply, transportation, and
            delivery, isn’t the core of our business. It isn’t what sets our
            products apart, and it isn’t the strength that we bring to the
            business. Production is Innovation, design and creation are our
            strengths and where we can make the biggest contribution to the
            business success. That is why we need a logistics planning. A good
            logistic planning service is operated by experts in supply chain
            management and organization. A specialist, dedicated logistic
            planning service can help us define our logistics needs both now and
            in the foreseeable future and put policies and processes in place
            that will keep our productive and profitable, come what may.
            <br /> A properly planned logistics function will ensure higher
            levels of customer satisfaction, reduce the number of disruptions
            our experience and mitigate the impact of those that cannot be
            avoided, all while reducing our actual costs. Competition is
            stiffening in just about every market. Efficiency, reliability and
            performance are more important than ever. Proper Logistics service
            can bring efficiency, reliability and improved profitability we
            need. Everyone knows about the importance of doing logistics
            planning, right?! After all, logistics is fundamental for the good
            functioning of the company, so it is vital to have total
            organization so that internal and external processes work properly.
            <br />
            Thinking about it, we decided to select some tips, so that you can
            understand a bit about good logistics planning.
          </p>
          <p className="md:hidden m-auto xxs:w-full mb-0 text-textLight text-justify font-lato lg:text-lg md:text-base xxs:leading-6 lg:leading-7 font-medium xxs:pt-4">
            <ReadMore>
              Every business has some logistics function. No matter what we are
              doing, we need to supply goods, services and/or information to our
              customers, and receive payment in return. ORIENT COMPANY shall be
              sourcing raw materials, parts, or finished products, transporting
              people, units, materials, and keeping everything moving smoothly.
              To stay competitive, we struggle every day to minimise costs,
              maintain and improve customer service levels avoid or mitigate
              disruptions of all kinds and improve the quality of all of our
              deliverables. The challenge here, is that logistics – supply,
              transportation, and delivery, isn’t the core of our business. It
              isn’t what sets our products apart, and it isn’t the strength that
              we bring to the business. Production is Innovation, design and
              creation are our strengths and where we can make the biggest
              contribution to the business success. That is why we need a
              logistics planning. A good logistic planning service is operated
              by experts in supply chain management and organization. A
              specialist, dedicated logistic planning service can help us define
              our logistics needs both now and in the foreseeable future and put
              policies and processes in place that will keep our productive and
              profitable, come what may. A properly planned logistics function
              will ensure higher levels of customer satisfaction, reduce the
              number of disruptions our experience and mitigate the impact of
              those that cannot be avoided, all while reducing our actual costs.
              Competition is stiffening in just about every market. Efficiency,
              reliability and performance are more important than ever. Proper
              Logistics service can bring efficiency, reliability and improved
              profitability we need. Everyone knows about the importance of
              doing logistics planning, right?! After all, logistics is
              fundamental for the good functioning of the company, so it is
              vital to have total organization so that internal and external
              processes work properly. Thinking about it, we decided to select
              some tips, so that you can understand a bit about good logistics
              planning.
            </ReadMore>
          </p>
        </div>
      </figure>

      <div className="logistic-container pt-20">
        {Logisticsdetails.map((detail, index) => {
          return (
            <div
              className={
                index % 2 === 0
                  ? "logistic-wrapper-even"
                  : "logistic-wrapper-odd"
              }
              key={index}
            >
              <div className="md:w-4/5 xxs:w-full border-b-[16px] border-corporateBg">
                <img src={detail.img} alt="details" />
              </div>
              <div className="xl:w-4/5 md:w-4/5  xxs:w-full">
                <h1 className="2xl:text-[40px] 2xl:leading-[48px] lg:text-[30px] lg:leading-[40px] md:text-[24px] xxs:text-lg font-black text-start font-lato pt-4 whitespace-nowrap">
                  {detail.heading}
                </h1>
                <p className="xxs:w-full mb-0 text-textLight text-justify font-lato lg:text-lg md:text-base xxs:leading-6 lg:leading-7 font-medium ">
                  {detail.details}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
