import { Fade } from "react-reveal";
import { Navbar } from "../NavBar";

export const Banner = (props) => {
  const { backgroundImage, title, gradient, discripation } = props;

  return (
    <>
      <div
        className="bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <Navbar />
        <div className={gradient} >
          <div
            className="h-[42vh] w-full"
          >
            <Fade top cascade>
              <div className="flex flex-col items-center justify-center h-full">
                <h1 className="text-white font-black font-lato xl:text-[60px] xl:leading-[72px]  md:text-[60px] md:leading-[70px] xxs:text-4xl ">
                  {title}
                </h1>
                <p className="text-lg leading-[27px] tracking-wide	font-lato font-normal text-white m-0">
                  {discripation}
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};
