export const Linkedin = () => {
  return (
    <>
      <svg
        id="Group_1227"
        data-name="Group 1227"
        xmlns="http://www.w3.org/2000/svg"
        width="31.854"
        height="31.768"
        viewBox="0 0 31.854 31.768"
      >
        <g id="Group_1226" data-name="Group 1226" transform="translate(0)">
          <path
            id="Path_2096"
            data-name="Path 2096"
            d="M1770.837,828.159c.441-.5.805-1,1.258-1.426a6.906,6.906,0,0,1,4.968-1.96,11.492,11.492,0,0,1,3.123.38,6.1,6.1,0,0,1,4.335,4.559,19.08,19.08,0,0,1,.577,5.224c.006,3.713-.011,7.426,0,11.138,0,.348-.1.439-.439.436q-2.869-.024-5.739,0c-.336,0-.409-.1-.408-.419q.016-5.3,0-10.6a9.523,9.523,0,0,0-.307-2.631,2.958,2.958,0,0,0-3.254-2.306c-2.268.12-3.447,1.243-3.737,3.549a13.016,13.016,0,0,0-.1,1.657c0,3.436,0,6.872.009,10.307,0,.343-.084.444-.434.441q-2.89-.026-5.78,0c-.309,0-.4-.081-.4-.393q.013-10.2,0-20.407c0-.336.11-.415.428-.412q2.744.02,5.489,0c.336,0,.423.106.416.425C1770.824,826.533,1770.837,827.347,1770.837,828.159Z"
            transform="translate(-1753.246 -814.745)"
            fill="#fff"
          />
          <path
            id="Path_2097"
            data-name="Path 2097"
            d="M1744.1,836.722c0,3.365-.006,6.73.008,10.1,0,.374-.091.487-.479.483-1.911-.021-3.824-.016-5.735,0-.307,0-.4-.074-.4-.391q.014-10.22,0-20.441c0-.282.068-.382.367-.381,1.94.013,3.88.016,5.819,0,.373,0,.423.141.422.461Q1744.088,831.633,1744.1,836.722Z"
            transform="translate(-1736.985 -815.533)"
            fill="#fff"
          />
          <path
            id="Path_2098"
            data-name="Path 2098"
            d="M1743.859,803.389a3.819,3.819,0,1,1-3.813-3.8A3.832,3.832,0,0,1,1743.859,803.389Z"
            transform="translate(-1736.221 -799.589)"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
};
