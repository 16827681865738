import {
  BrandImage1,
  BrandImage10,
  BrandImage11,
  BrandImage13,
  BrandImage14,
  BrandImage15,
  BrandImage16,
  BrandImage18,
  BrandImage2,
  BrandImage3,
  BrandImage4,
  BrandImage44,
  BrandImage5,
  BrandImage6,
  BrandImage7,
  BrandImage8,
  BrandImage9,
  BridgeGo,
  CivilImage,
  Destination,
  DMCpara2,
  DMCpara3,
  Donesvg,
  EmbroideryImage1,
  EmbroideryImage2,
  EmbroideryImage3,
  GoodSuppliers,
  // HighestEmbroidery,
  InventoryManagement,
  LarPcoImage,
  Operates1,
  Operates2,
  Operates3,
  Operates4,
  Operates5,
  Operates6,
  Operates7,
  Operates8,
  Operates9,
  ProductsAndServices2,
  ProductsAndServices3,
  ProductsAndServices4,
  ProductsAndServices5,
  ProductsAndServices6,
} from "../assets";

export const ProductsAndServicesData = [
  {
    image: ProductsAndServices3,
    title: "Trading & Procurement",
    about:
      "Today’s procurement professionals feel the pressure of organinzing competing priorities on a daily basis.",
    link: "/",
  },
  {
    image: ProductsAndServices2,
    title: "Hvac-System",
    about:
      "Get the basics about HVAC systems so you can make an educated purchasing decision",
    link: "/",
  },
  {
    image: CivilImage,
    title: "Civil & Mechanical",
    about:
      "Archives of Civil and Mechanical Engineering (ACME) publishes original research articles which explore new ideas and techniques",
    link: "/",
  },
  {
    image: ProductsAndServices4,
    title: "Satellite System & CCTV",
    about:
      "Securing remote locations for safety of staff or protection of corporate assets can be accomplished.",
    link: "/",
  },
  {
    image: ProductsAndServices5,
    title: "Abx/Epabx System",
    about:
      "A switching system that makes available both internal and external stitching functions of any organisation",
    link: "/",
  },
  {
    image: ProductsAndServices6,
    title: "IT Support & Supply",
    about:
      "Users can get support for their devices whenever and however they need it based on their circumstances.",
    link: "/",
  },
];

export const FooterServices = [
  {
    name: "Trading & Procurement",
    link: "/",
  },
  {
    name: "HVAC-System",
    link: "/",
  },
  {
    name: "IT Support & Supply",
    link: "/",
  },
  {
    name: "Logistic Support",
    link: "/",
  },
  {
    name: "Manpower",
    link: "/",
  },
];
export const FooterLinks = [
  {
    name: "DMC DIVISION",
    link: "/",
  },
  {
    name: "DMC SERVICES",
    link: "/",
  },
  {
    name: "Features",
    link: "/",
  },
  {
    name: "Pricing",
    link: "/",
  },
  {
    name: "Blog",
    link: "/",
  },
];

// export const TeamPeoples = [
//   {
//     img: TeamMember1,
//     name: "Christine Eve",
//     profile: "Developer",
//   },
//   {
//     img: TeamMember2,
//     name: "Mike Hardson",
//     profile: "Developer",
//   },
//   {
//     img: TeamMember3,
//     name: "Mike Hardson",
//     profile: "Developer",
//   },
//   {
//     img: TeamMember4,
//     name: "Christine Eve",
//     profile: "Developer",
//   },
// ];

export const OperatesData = [
  {
    image: Operates1,
    name: "Trading & Procurement",
  },

  {
    image: Operates3,
    name: "HVAC-System",
  },
  {
    image: Operates4,
    name: "Civil",
  },

  {
    image: Operates6,
    name: "Logistic Support",
  },
  {
    image: Operates7,
    name: "Oil & Gas",
  },

  {
    image: Operates2,
    name: "Satellite System & CCTV",
  },
  {
    image: Operates5,
    name: "ABX/EPABX Systems",
  },

  {
    image: Operates8,
    name: "IT Support & Supply",
  },
  {
    image: Operates9,
    name: "Manpower",
  },
];

export const Productsupport = [
  {
    icon: <Donesvg />,
    text: "Control of Inspection, Measuring and Test Equipment",
  },

  {
    icon: <Donesvg />,
    text: "Verification of Basic Measuring Tools",
  },
  {
    icon: <Donesvg />,
    text: "Verification of Torque Setting Instruments",
  },
  {
    icon: <Donesvg />,
    text: "Verification of Electrical Measuring Instruments",
  },
  {
    icon: <Donesvg />,
    text: "Product Handling",
  },
  {
    icon: <Donesvg />,
    text: "Product Storage and Preservation",
  },

  {
    icon: <Donesvg />,
    text: "Product Packaging",
  },

  {
    icon: <Donesvg />,
    text: " Product Delivery",
  },
];
export const Qualityoperation = [
  {
    icon: <Donesvg />,
    text: "Inspection Record",
  },

  {
    icon: <Donesvg />,
    text: "Receiving Inspection and Testing",
  },
  {
    icon: <Donesvg />,
    text: "Job Record Card",
  },
];

export const QualityProcedureManagement = [
  {
    icon: <Donesvg />,
    text: "Internal Quality System Audits",
  },

  {
    icon: <Donesvg />,
    text: "Internal Quality System Audits",
  },
  {
    icon: <Donesvg />,
    text: "Quarterly Nonconformance Analysis",
  },
];

export const Dmcdetails = [
  {
    img: Destination,
    details:
      "The Company with its authentic experience in the aims to provide high unmatched standards of professionalism & performance, with that effect, it has an experienced & professional management team assisted by dedicated team of sales & marketing staff, field representatives, multi-lingual tour guides & coordinators, tour consultants & support staff",
  },
  {
    img: DMCpara2,
    details:
      "Our Specialists find unique, high-quality places to stay, things to see, and ways to make your vacation or business trip more fun and more Exciting during your stay in Kuwait.The Company specializes in bringing Leisure or Business FIT’s, Individuals and Groups from Europe, Japan, USA, Asia, Gulf, Middle East, and Australia into Kuwait.The Company specializes in bringing Leisure or Business FIT’s, Individuals and Groups from Europe, Japan, USA, Asia, Gulf, Middle East, and Australia into Kuwait.",
  },

  {
    img: DMCpara3,
    details:
      "Our next aim is to develop the potential in Kuwait for cruise calls where we offer a high quality of service combined with the utmost efficiency and attention to every detail of the operation be it excursions or logistics support. We strongly believe that if we do our job well, you will fall in love with our destination & will return time & time again. From your initial telephone call or contact with us through the Web site, you can feel confident that you are dealing with people who care about providing you with a professional & efficient service.",
  },
];

// export const Embroiderydetails = [
//   {
//     img: HighestEmbroidery,
//     heading: "Highest Quality Embroidery",
//     details:
//       "Our embroidery is of the highest quality. Our attention to detail is second to none. The end result for you is, top-of-the-line, state of the art, cream of the crop embroidery. In other words our embroidery is pretty “darn” good. Plus our prices are much lower than the quality warrants. There is no minimum order quantity. We want you to look good without paying a fortune. Embroidered apparel is an excellent way of promoting your business. It is less expensive than many other forms of advertising and it lasts. Every time someone is wearing your company logo they are promoting your business, at the supermarket, on the golf course, everywhere they go. Contact us today to see what we can do for you",
//   },
//   {
//     img: DigitizingImage,
//     heading: "What is Digitizing?",

//     details:
//       "Obviously, the term digitizing can be a little confusing. The word relates to how we write the software that can interpret your design and convert graphical images into embroidery stitches that can then be understood and ultimately sewn by an embroidery machine. We are often asked if a scanned image can be sewn. The answer is “no”. We do have to convert it to an embroidery format first. We use the latest and greatest in digitizing software. In our digitizing we optimize the design for performance and visual appeal and we strive to match your requirements as closely as possible. Our in-house digitizing service will ensure an accurate reproduction of your design.",
//   },
// ];

export const Logisticsdetails = [
  {
    img: GoodSuppliers,
    heading: "Have Reliable and Good Suppliers",
    details:
      "Every company needs to get products and materials needed to produce its product. In the case of services, the company also needs good suppliers. Project managers are always on the lookout for product quality, but that is not always enough. For the correct choice, it is necessary to evaluate other things, such as: cost x benefit, quality of transportation and delivery, fulfilment of promised deadlines, the distance that the supplier is from the company, etc. Analyzing all these items together will lead you to choose the best logistics provider for your company.",
  },
  {
    img: InventoryManagement,
    heading: "Optimize Inventory Management",

    details:
      "A very large or very small stock poses a problem for the company as it is dangerous to run out of stock or have stranded stock.Large stocks also increase storage costs and make stocking more difficult. That is why inventory management is such an important factor in the logistics industry. Several metrics are used to help decide how much demand the stock can meet, identifying when to order more product for inventory, etc. The metrics serve to quantitatively measure everything that has been addressed above.",
  },
];

export const ImagesFeedback = [
  { image: BrandImage1, name: "Embraer" },
  { image: BrandImage2, name: "Embraer" },
  { image: BrandImage3, name: "Embraer" },
  { image: BrandImage16, name: "Embraer" },

  { image: BrandImage4, name: "Embraer" },
  { image: BrandImage5, name: "Embraer" },
  { image: BrandImage6, name: "Embraer" },

  { image: BrandImage44, name: "Embraer" },
  { image: BrandImage7, name: "Embraer" },
  { image: BrandImage8, name: "Embraer" },

  { image: BrandImage9, name: "Embraer" },
  { image: BrandImage18, name: "Embraer" },
  { image: BrandImage10, name: "Embraer" },

  { image: BrandImage11, name: "Embraer" },
  { image: BridgeGo, name: "Embraer" },
  { image: LarPcoImage, name: "Embraer" },

  { image: BrandImage13, name: "Embraer" },
  { image: BrandImage14, name: "Embraer" },
  { image: BrandImage15, name: "Embraer" },
];

export const Tradingdata = [
  {
    heading: " ELECTRICAL EQUIPMENTCABLES & WIRES",
    details:
      "High & Medium Voltage PVC / XLPE Insulated Armoured Power Cables Low Voltage PVC / PVC and PVC Flexible Cables Rubber Flexible ables single Core wires, Coaxial Cables, Data Cables CAT 5/6, fibre Optic Cables.",
    Brand:
      "Brand: Gulf Cable, Jordan Gulf, Oman Cable, Belden, Top Cable, Optronics, Linnet, 3M, Hubble.",
  },
  {
    heading: "CABLE MANAGEMENT",

    details:
      "Weather Proof Halogen, Metal Halide & Sodium fittings, Lowbay & Highbay fittings, Hazardous Area Fittings. Weather Proof Fluorescent Fittings, Street Fittings Batten, Louvre, Recessed Fluorescent Fittings. Including all types of Lamps standard, Photo optic & Medical Lamps. ",
    Brand:
      "Brand: Philips, Deco, Osram, Fitzgerald, Tamelite, Atlantis, Dolphin, GE, Neptune",
  },
  {
    heading: "PANELS & DISTRIBUTION BOARDS INDOOR & OUTDOOR",

    details:
      "Main and Submain Distribution Boards, Consumer Distribution Boards, Change over switches, A.T.S Panels.",
    Brand:
      "Brand: Hager, GCG, Cutler Hammer, Bill, Eletra, Telegron, C&S, Square D.",
  },
  {
    heading: "SWITCHGEAR & EARTHING EQUIPMENT",

    details:
      "Air circuit Breakers, MCCB’s, MCB’s, Main Switches, Isolators, Transformers, RCCB, RCBO, Contractors, Relays, Phase Failures, Industrial Plugs & Sockets, Earth Rod Sets, Copper Tapes, Digital & Analog Millimetres, Clamp Meters & Insulation Testers etc. ",
    Brand:
      "Brand:Cutler Hammer, Merlin Gerin, Square D, Legrand, ABB, Hager, GE, Furse, Hioki, Lewden.",
  },
  {
    heading: "GI & PVC CONDUIT FITTINGS, WIRING ACCESSORIES &FANS",

    details:
      "GI & PVC Conduit Pipes, Switches, Sockets Dimmer Switches, Concealed & surface boxes ceiling accessories, Shaver sockets, Industrial & Indoor Ventilating Fans, Ceiling, Wall & Pedestal Fans, etc.",
    Brand:
      "Brand:Legrand, MK, BG, Scame, Edison, Crabtree, Volex, Tenby, Adsani, Khorafi Plastics, Usha, Panasonic, KDK, Shams, Sinar, Deta, Contactum.",
  },
  {
    heading: "POWER SUPPLIES , ELECTRICAL",
    details:
      "Plasma, LCD, Washing Machines, Microwaves, Fridges, HIFI Systems, Computers with Keyboard & Mouse, Laptops, Printers, Plotters, Digital Sender ,GPS Navigation, GPS Systems etc.",
  },
  {
    heading: "HVAC",
    details:
      " AC Refrigeration, Air Treatment, Blowers, Central Equipment, Controls, Exhaust Fans, Exterior Products, Fans, Misting Fans, Filters, Heating Equipment, Ventilators, Duct Tapes.",
  },
  {
    heading: "LUBRICANTS",
    details: "Marine, Commercial & Industrial Oils. ",
  },
  {
    heading: "AUTOMOTIVE",
    details: "Spare Parts, Tyres, Filters, Run flats.",
  },
  {
    heading: "STEEL",
    details: "SS Steel, Mild steel, Aluminum, Steel Studs, etc.",
  },
  {
    heading: "PUMPS & PLUMBING",
    details:
      "Chemical, Circulation, Connectors & Seals, Controls, Drum & Barrel, Filtration, Portable fire, Pumps, Fittings, Fixtures Gear, Generators, High Pressure hoses, pipe & Tubing, Pool & Spa, Pump Accessories, Sump & sewage, Transfer, Valves & Wells.",
  },
  {
    heading: "OLE FIELD SUPPLIES",
    details:
      "Gravel, Gatch, Fluid Synthetic Organic, Joint Sealant Concrete (Penetron 4037), Penetron 4037 clean out Fluid, Liquid Sealant (Exposit SB, Curing Compound. (UV Safe Seal), Asphalt Emulsified, Asphalt Cold Patch, Barafos, Ben Seal, Quickgel, Quick Grout, EZ-Seal, E-Z Mud, S-40 Activator, Expanding Foam, Soil Stabilizer, Concrete Hardener & dust Proofing Compound, etc.",
  },
  {
    heading: "CIVIL CONSTRUSTION MATERIAL",
    details:
      "Cras dictum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Aenean lacinia mauris vel est.",
  },
  {
    heading: "IT & TELECOM",
    details:
      " From Two-way Radios to complete Telephone, Exchanges/Switches & Satellites & All Possible Communications Equipment, Radio Towers, Computer network Design & Installation.",
  },
  {
    heading: "OFFICE SUPPLIES",
    details:
      "Adhesive Labels, Ball Pen & accessories, Pencils, Binder Accessories, Calculator, Hanging File, Manila Folder, Folder Divider, Clips & Fasteners, Correction Pens, Desk Accessories, A4 size Paper, Photocopy Paper (Different Sizes), Permanent Markers, Non- Permanent Markers, etc ",
  },
  {
    heading: "FURNITURE AND FIXTURES",
    details:
      "High Back chair, Low Back Chair, Arm chair, Wardrobe, Night Stand, Bunk Bed, Twin Size Bed, Conference Table, Office Desk, Wooden Desk, Dining Table, etc. ",
  },
  {
    heading: "HAREWARE MATERIALS",
    details:
      "Nails (Various sizes), Blind Hinges, Bolts, Pulleys, Locks, Cords, Door hangers, Strap Hinges, Cupboard Catches, Hooks, Screws, Sandpaper, etc.",
  },
  {
    heading: "HEAVY EQUIPMENTS (RENTAL & LEASING)",
    details:
      "Tower Lights, Generators, Forklifts, Scissor Lifts, Pressure Washers.",
  },
  {
    heading: "MEDICAL EQUIPMENT & SUPPLIES",
    details:
      "Infusion Pumps, Defibrillator, Dressing cart, Oxygen Cylinder, Thermometer, Stretcher, etc.",
  },
  {
    heading: "INSULATIONS",
    details: "Styrofoam, Polystyrene, Polyurethane, Fiberglass, etc.",
  },
  {
    heading: "MANPOWER SUPPLY",
    details: " Management Skilled, Semi Skilled & Unskilled personnel.",
  },
  {
    heading: "CONSTRUCTION EQUIPMENT (RENTAL & LEASING)",
    details: " Forklifts, Cranes, Transportation Trucks, Coaster Buses, etc.",
  },
  {
    heading: "PRINTING & EMBROIDERY",
    details:
      " Visiting Cards, Bill Books & Letterhead, Brouchuers, posters & Banners, Sticker & Stamps, Sign Board, Gift Items Printing, Embroidery on Coverall, T-shirt, Caps etc.",
  },
];

export const difference = [
  {
    title: "Monograms: ",
    para: "Using a single color of thread, a name, initials, or a short phrase are stitched directly into the fabric. The character limit for words is generally 10 or less. The thread color you can select depends on the product receiving the monogram; it is best to produce a visible contrast so that the message can be easily read. For example, yellow on purple are complementary colors and allow both hues to be equally visible.",
  },
  {
    title: "Embroidery:",
    para: "A variety of thread colors are stitched onto the fabric to replicate a predetermined icon. This is a common method used to display business and event logos. Many items take embroidery as well, including women’s work blazers, custom uniform work sweaters, button-down shirts, duffel bags, and hand towels. If your image is too complicated for a monogram, then this upgrade is an option.",
  },
  {
    title: "Screen printing: ",
    para: "This method doesn’t require any stitching. Instead, ink is transferred to the material surface with a machine. A blocking stencil controls where the ink goes so that the custom image is replicated exactly how it is supposed to look. This method can be used on certain types of fabrics and other surfaces like vinyl.",
  },
];

export const groupOfClothes = [
  {
    image: EmbroideryImage1,
  },
  {
    image: EmbroideryImage2,
  },

  {
    image: EmbroideryImage3,
  },
];

export const designGoals = [
  {
    goals: "A Guide Everyone Needs for Corporate Gifting",
  },
  {
    goals: "Gifting for BeCorporateginners",
  },
  {
    goals: "How to Impress as an Event Manager",
  },
  {
    goals: "How to Plan the Best Company Party",
  },
  {
    goals: "How to Plan a Successful Company Event",
  },
];

// emailToPerson
export const emailToPerson = (values) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      margin: 0;
      padding: 0;
    }

    .email-container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    .header {
      text-align: center;
      margin-bottom: 20px;
    }

    .logo {
      max-width: 150px;
      height: auto;
    }

    .thank-you-message {
      text-align: center;
    }

    .content {
      margin-top: 30px;
    }
  </style>
</head>
<body>
  <div class="email-container">
    <div class="header">
      <a href="https://orientgrp.com/" target="_blank"><img class="logo" src="https://orientgrp.com/static/media/Orient.2bd216827de29da19096a71394cd0bfa.svg" alt="Orient Logo"></a>
    </div>
    <div class="thank-you-message">
    <h1>Hello,${values.name} !!</h1>
      <h4>Thank You for Contacting Us!</h4>
      <p>We appreciate your interest. Our team will review your message and contact you shortly.</p>
    </div>
    <div class="content">
      <p>If you have any urgent questions or need immediate assistance, please feel free to call us at <strong>+(965)-50642420</strong>.</p>
    </div>
  </div>
</body>
</html>
    `;
};

//emailFunction for company team
export const emailFunction = (values) => {
  return `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      margin: 0;
      padding: 0;
    }

    .email-container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    .header {
      text-align: center;
      margin-bottom: 20px;
    }

    .logo {
      max-width: 150px;
      height: auto;
    }

    .query-details {
      margin-top: 30px;
      border: 1px solid #ccc;
      padding: 20px;
      background-color: #f7f7f7;
    }

    .query-item {
      margin-bottom: 15px;
    }

    .query-item label {
      font-weight: bold;
    }
  </style>
</head>
<body>
  <div class="email-container">
    <div class="header">
    <a href="https://orientgrp.com/" target="_blank"><img class="logo" src="https://orientgrp.com/static/media/Orient.2bd216827de29da19096a71394cd0bfa.svg" alt="Orient Logo"></a>
    </div>
    <div class="query-details">
      <h2>New Query Received !</h2>
      <div class="query-item">
        <label>Name:</label>
        <p>${values.name}</p>
      </div>
      <div class="query-item">
        <label>Email:</label>
        <p>${values.email}</p>
      </div>
      <div class="query-item">
        <label>Contact Number:</label>
        <p>${values.phone_number}</p>
      </div>
      <div class="query-item">
        <label>Message:</label>
        <p>${values.message}</p>
      </div>
    </div>
  </div>
</body>
</html>

    `;
};
