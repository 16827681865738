import { Navbar } from "../";
import {
  ContractingBanner,
  DmcBanner,
  Womanworking,
  firstSlider,
} from "../../assets";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 800 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 799, min: 500 },
    items: 1,
  },
  smmobile: {
    breakpoint: { max: 499, min: 0 },
    items: 1,
  },
};

const HomeBannerBackgroundImages = [
  {
    id: 1,
    banner: firstSlider,
  },

  {
    id: 2,
    banner: DmcBanner,
  },
  {
    id: 3,
    banner: Womanworking,
  },
  {
    id: 4,
    banner: ContractingBanner,
  },
];

const renderImage = () => {
  return HomeBannerBackgroundImages.map((instance, index) => {
    return (
      <>
        <div className="w-full h-[90vh]" key={index}>
          <div
            className="w-full h-[90vh] bg-cover bg-no-repeat bg-center flex"
            style={{ backgroundImage: `url(${instance.banner})` }}
          >
            {index !== 0 && (
              <div className="flex flex-col justify-center  items-center bg-homebannerGradient xl:w-[60%] lg:w-[80%] 2xl:p-20  lg:p-16 xxs:w-[90%] m-auto md:p-20 xxs:p-12">
                <h1 className="text-white xxs:text-xl md:text-5xl lg:text-[72px] lg:leading-[98px] font-[lato] font-black xxs:text-center mb-[0.3em]">
                  Orient Company
                </h1>
                <p className="2xl:w-[85%] md:3/5  xl:w-[95%] md:text-center  xxs:w-full text-white xxs:text-base md:text-lg md:leading-7 font-lato font-medium m-0 xxs:text-center py-2">
                  Orient Company is committed to the consistent pursuit of
                  excellence and continuous dedication in improving its business
                  to provide clients with products, services that fully comply
                  with their requirements.
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  });
};

export const HomeBanner = () => {
  const CustomDot = ({ onClick, active }) => {
    return (
      <div className={active ? "active" : "inactive"} onClick={() => onClick()}>
        <div
          className={
            active
              ? " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-corporateBg border-2 mb-4 border-solid border-corporateBg"
              : " w-2.5 h-2.5 rounded-full ml-2 flex flex-row items-center justify-center text-center m-auto bg-transparent border-2 mb-4 border-solid border-white"
          }
        ></div>
      </div>
    );
  };
  return (
    <>
      <Navbar />
      <div>
        <div className="relative">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={true}
            customDot={<CustomDot />}
            removeArrowOnDeviceType={[
              "mobile",
              "tablet",
              "desktop",
              "superLargeDesktop",
              "smmobile",
            ]}
          >
            {renderImage()}
          </Carousel>
        </div>
      </div>
    </>
  );
};
