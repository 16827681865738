import { dmcBackground, } from "../../assets";
import { Banner, Footer,  } from "../../Components";
import { DmcOrientGroup } from "../../Container";

export const DMCdivision = () => {
  return (
    <div id="dmcdivision">
      <Banner
        gradient="bg-bannerBgColor"
        backgroundImage={   dmcBackground}
        title="DMC Division"
        discripation="Destination Management Company"
      />
      <DmcOrientGroup/> 

      <Footer/>
    </div>
  );
};
