import { DetailsForm } from "../DetailsForm";

export const FeedBack = () => {
  return (
    <div className="flex xxs:flex-col lg:flex-row">
      <div
        className="  flex items-center justify-center lg:px-0 lg:py-0 md:px-8 md:py-0 xxs:py-8
        "
      ></div>
      <div className="w-full bg-feedBackImg bg-cover bg-center ">
        <div className="bg-feedBackBg h-full">
          <div className="xxs:w-11/12 xl:w-3/4 m-auto md:px-8 md:py-24 xxs:py-8">
            <div className="">
              <div>
                <h1 className="text-white text-start md:leading-[58px] md:text-5xl font-black xxs:text-2xl font-lato">
                  Contact Us
                </h1>
                <div className="bg-primary w-[88px] h-0.5"></div>
              </div>
              <div className="pt-12">
                <DetailsForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
