import {  tradingBackground } from "../../assets"
import { Banner, Footer } from "../../Components"
import { TradingOrientGroup } from "../../Container/TradingOrientGroup"

export const TradingDivision = () => {
    return(
        <div id="trading">
        <Banner
        gradient="bg-bannerBgColor"
        backgroundImage={tradingBackground }
        title="Trading Division"
        discripation="Hardware and Manufacturer"
        // id="#embroidery"
      />
      <TradingOrientGroup/>
      <Footer/>
        </div>
    )
}