export const Linkarrow = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.257"
        height="12.175"
        viewBox="0 0 19.257 12.175"
      >
        <path
          id="Path_2150"
          data-name="Path 2150"
          d="M2729.5,2411.281a.8.8,0,0,1,.864-.529q7.915.01,15.831,0h.218l.032-.05a1.326,1.326,0,0,1-.158-.12q-2.009-2.005-4.017-4.013a.723.723,0,0,1-.258-.668.666.666,0,0,1,1.072-.417,1.635,1.635,0,0,1,.166.153l5.214,5.21a.714.714,0,0,1,0,1.153q-2.618,2.621-5.235,5.241a.707.707,0,0,1-.74.245.667.667,0,0,1-.345-1.056,2.012,2.012,0,0,1,.167-.178q1.994-1.994,3.991-3.986a1.343,1.343,0,0,1,.147-.112l-.024-.049h-.2q-7.934,0-15.868,0a.805.805,0,0,1-.864-.531Z"
          transform="translate(-2729.497 -2405.344)"
          fill="#fff"
        />
      </svg>
    </>
  );
};
