import { useEffect, useState } from "react";
import { MenuIcon, Orient } from "../../assets";
import { Drawer } from "antd";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line no-unused-vars
  const [height, setHeight] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setHeight(document.documentElement.scrollHeight);
  });

  const NavbarItem = [
    {
      name: "Home",
      href: "/",
    },

    {
      name: "About Us",
      href: "/about",
    },
  ];

  return (
    <div className="bg-carbon-fiber py-3">
      <div className="xxs:w-11/12 lg:w-10/12 m-auto flex xxs:justify-between  lg:justify-center lg:items-center">
        <div className="xxs:hidden lg:block w-full">
          <div className="w-full xxs:hidden lg:flex items-center flex-row">
            <div className="w-2/5">
              <ul className="flex flex-row justify-end items-center lg:gap-12 2xl:gap-24 font-lato tracking-wider font-medium m-0">
                <li>
                  <Link
                    to="/"
                    className="text-white md:text-sm xl:text-base xl:leading-5 whitespace-nowrap font-lato font-bold"
                  >
                    Home
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-1/5 m-auto">
              <div className="xl:w-32 lg:w-16 h-auto m-auto grow">
                <a href="/">
                  <img src={Orient} alt="logo" />
                </a>
              </div>
            </div>
            <div className="w-2/5 ">
              <ul className="flex flex-row justify-start lg:gap-12 2xl:gap-24  items-center  font-[lato] tracking-wider font-medium m-0">
                <li>
                  <Link
                    to="/about"
                    className="text-white md:text-sm xl:leading-5 xl:text-base whitespace-nowrap font-bold"
                  >
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:justify-end lg:hidden">
          <a href="/">
            <div className="xxs:w-16 lg:w-16 h-auto ">
              <img src={Orient} alt="logo" />
            </div>
          </a>
        </div>
        <div className="flex lg:justify-end lg:hidden">
          <button onClick={showDrawer}>
            {/* <img src={Menu} alt="menu" className="h-6 w-6 menu-mobile" /> */}
            {/* <img src={MenuWhite} alt="menu" className="h-6 w-6"/> */}
            <MenuIcon />
          </button>
          <Drawer
            title="Orient Company"
            className="customeDrower"
            placement="right"
            onClose={onClose}
            open={open}
          >
            <ul className="flex flex-col items-start gap-12 tracking-wider font-semibold">
              {NavbarItem.map((item, index) => {
                return item.image ? null : (
                  <li className="xl:w-32" key={index}>
                    <Link
                      to={item.href}
                      className="text-buttonBg md:text-sm  xl:text-base whitespace-nowrap"
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Drawer>
        </div>
      </div>
    </div>
  );
};
