import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { About, Home } from "./Pages";
import { useEffect, useState } from "react";
import { ContractingDivision } from "./Pages/ContractingDivision";
import { DMCdivision } from "./Pages/DMCdivision";
import { TradingDivision } from "./Pages/TradingDivision";
import { EmbroideryDivision } from "./Pages/EmbroideryDivision";
import { LogisticsDivision } from "./Pages/LogisticsDivision";
import { ScrollButton } from "./Components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  const routePath = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    });
    onTop();
  }, [routePath]);

  return (
    <div className="App">
      <ToastContainer position="top-right" autoClose={5000} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/contracting-division"
          element={<ContractingDivision />}
        />{" "}
        <Route path="/trading-division" element={<TradingDivision />} />
        <Route path="/embroidery-division" element={<EmbroideryDivision />} />
        <Route path="/DMC-division" element={<DMCdivision />} />
        <Route path="/logistics-division" element={<LogisticsDivision />} />
      </Routes>
      {showScrollButton && <ScrollButton />}
    </div>
  );
}

export default App;
