import React from "react";
import { contractingBackground } from "../../assets";
import { Banner, Footer } from "../../Components";
import { CorporateQualityPolicy, OrientGroup } from "../../Container";

export const ContractingDivision = () => {
  return (
    <div id="contracting">
      <Banner
        gradient="bg-bannerBgColor"
        backgroundImage={contractingBackground}
        title="Contracting Division"
        discripation="Integrity . Innovation . Knowledge"
        // id="#embroidery"
      />
      <OrientGroup />
      <CorporateQualityPolicy />

      <Footer />
    </div>
  );
};
