import React from "react";
import CountUp from "react-countup";
import { Fade } from "react-reveal";

const RewardsData = [
  {
    count: 500,
    name: "SATISFIED CLIENTS",
  },
  {
    count: 121,
    name: "TIMES GOOD",
  },
  {
    count: 87,
    name: "BEST RATING",
  },
  {
    count: 70,
    name: "CENTRES",
  },
];

export const Awards = () => {
  return (
    <div className=" lg:h-[40vh] w-full m-auto bg-fixed bg-CountBackground bg-center bg-no-repeat bg-cover font-dmSerif tracking-wider xxs:mt-12 lg:mt-0">
      <div className="bg-lightBlack h-full">
        <div className="xxs:w-11/12 md:w-10/12 m-auto h-full xxs:py-12 lg:py-0">
          <Fade bottom cascade>
            <div className="h-full flex xl:flex-row xxs:flex-wrap xl:flex-nowrap  xxs:justify-start sm:justify-center  lg:justify-center items-center xxs:mt-8 lg:mt-24">
              {RewardsData.map((item, index) => {
                return (
                  <div
                    className={
                      index === 0
                        ? " xl:w-[20%] lg:w-[25%] xxs:p-4 lg:p-0 text-center group lg:border-dashed lg:border-corporateBg xxs:w-full sm:w-[45%] "
                        : "xl:w-[20%] lg:w-[25%] xxs:p-4 lg:p-0 text-center group lg:border-l-4 lg:border-dotted lg:border-corporateBg   xxs:w-full sm:w-[45%] "
                    }
                    key={index}
                  >
                    <div className="xl:text-[70px] xl:leading-[96px] sm:text-6xl  xxs:text-5xl font-black text-corporateBg leading-normal lg:group-hover:text-corporateBg font-lato">
                      <CountUp
                        start={0}
                        end={item.count}
                        enableScrollSpy={true}
                        redraw={true}
                        className="font-lato"
                      >
                        {({ countUpRef, start }) => (
                          <div>
                            <span ref={countUpRef} />
                            <span className="xl:text-[70px] xl:leading-[96px] sm:text-6xl  xxs:text-5xl font-black text-corporateBg font-lato">
                              +
                            </span>
                          </div>
                        )}
                      </CountUp>
                    </div>
                    <p className="text-white xxs:leading-4 font-semibold   xxs:text-sm  font-montserrat tracking-wider xl:mt-2 xxs:mt-4">
                      {item.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};
