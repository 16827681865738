import React from "react";
import { Contracting2 } from "../../assets";

export const OrientGroup = () => {
  return (
    <div className="xxs:w-11/12 lg:w-10/12 2xl:w-[62%] xl:3/4 m-auto md:py-32 xxs:py-20" >
      <p className=" text-justify  text-textLight">
        <b className="text-black md:text-[22px] md:leading-8 font-black font-lato">ORIENT COMPANY </b><span className="md:text-lg md:leading-8 xxs:leading-6  font-lato">is “customer oriented” with the management placing
        strong emphasis on the provision of quality services by engaging
        qualified & experienced Engineers Technicians and Skilled workers. Our
        staffs are motivated to establish good customer relationship.</span>
      </p>
      <div className="w-full ">
        <img
          src={Contracting2}
          alt="Contracting2"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};
