import { ContractingScratchingImage } from "../../assets";
import {
  Productsupport,
  Qualityoperation,
  QualityProcedureManagement,
} from "../../constant";

export const CorporateQualityPolicy = () => {
  return (
    <>
      <div>
        <div className="bg-gradient-to-b from-corporateBg to-clientfeedbackgradient2 ">
          <div
            className="w-full m-auto pt-20"
          >
            <div className="h-full w-full">
              <img
                src={ContractingScratchingImage}
                alt="Contacting"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
        <div className="md:mt-[-9%] xxs:mt-[-5%]">
          <p className="font-lato md:text-base xxs:text-sm leading-5 text-black ">
            QUALITY ASSURANCE
          </p>
          <h4 className="xl:text-[40px] xl:leading-[48px] lg:text-[35px] md:text-[30px] md:leading-[48px] font-black xxs:text-base text-black">
            Corporate Quality Policy Statements
          </h4>
          <p className="md:text-justify xxs:text-justify font-medium md:text-lg font-lato leading-7 text-textLight  lg:w-3/5 md:w-[75%] xxs:w-[90%] m-auto md:py-0  xxs:pb-8">
            ORIENT COMPANY is dedicated to providing high quality services to
            industries by adhering to safety and customer requirements. OC’s
            total commitment is to meet all contractual responsibilities
            professionally by paying full attention to details of all aspects of
            works. It is achieved by employing qualified and experienced staff
            and provides all new entrants with continuous training;
            comprehensive quality planning enduring effort motivates innovative
            ideas, integrated team work plus the continuous monitoring and
            review of quality requirements. By this commitment OC will maintain
            and gain the customer satisfaction. Following Procedure are
            formulated to implement Quality Assurance and Quality Control plan.
          </p>
        </div>

        <div className="xxs:w-[90%] lg:w-[85%] 2xl:w-[62%] xl:w-[70%] m-auto md:py-20 xxs:py-12">
          <h1 className="text-start xl:text-[40px] xxs:text-xl xl:leading-[48px] md:text-4xl font-lato font-black md:py-16 xxs:py-4">
            Quality Product Support
          </h1>
          <div className="grid lg:grid-cols-3 xxs:grid-cols-1 lg:gap-1 md:gap-4 xxs:gap-2">
            {Productsupport.map((item, index) => {
              return (
                <>
                  <div
                    className="flex items-center bg-boxbgColor gap-4 xl:py-6 xl:px-6 xxs:py-3 xxs:px-3"
                    key={index}
                  >
                    <div>{item.icon}</div>

                    <p className="font-bold md:text-base text-left mb-0 md:leading-5 xxs:text-sm	font-lato text-footerbgColor">
                      {item.text}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="xxs:w-[90%] lg:w-[85%] 2xl:w-[62%] xl:w-[70%] m-auto  ">
          <h1 className="text-start xl:text-[40px] xxs:text-lg md:text-4xl xl:leading-[48px] font-lato font-black md:py-16 xxs:py-4	">
            Quality Operation
          </h1>
          <div className="grid lg:grid-cols-3 xxs:grid-cols-1 lg:gap-1 md:gap-4 xxs:gap-2">
            {Qualityoperation.map((item, index) => {
              return (
                <>
                  <div
                    className="flex items-center bg-boxbgColor gap-4 xl:py-6 xl:px-6  xxs:py-3 xxs:px-3"
                    key={index}
                  >
                    <div>{item.icon}</div>

                    <p className="font-bold md:text-base  xxs:text-sm md:leading-5	font-lato mb-0 text-left text-footerbgColor">
                      {item.text}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="xxs:w-[90%] lg:w-[85%] 2xl:w-[62%] xl:w-[70%] m-auto md:py-28 xxs:py-16 ">
          <h1 className="text-start xl:text-[40px] xxs:text-lg xl:leading-[48px] md:text-4xl font-lato font-black md:py-16 xxs:py-4">
            Quality Procedure Management Control
          </h1>
          <div className="grid lg:grid-cols-3 xxs:grid-cols-1 lg:gap-1 md:gap-4 xxs:gap-2">
            {QualityProcedureManagement.map((item, index) => {
              return (
                <>
                  <div
                    className="flex items-center bg-boxbgColor gap-4 xl:py-6 xl:px-6 xxs:py-3 xxs:px-3"
                    key={index}
                  >
                    <div>{item.icon}</div>
                    <p className="font-bold text-left md:text-base xxs:text-sm md:leading-5	font-lato mb-0 text-footerbgColor">
                      {item.text}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
