import {  AboutCompanyImage } from "../../assets";

export const AboutCompany = () => {
  return (
    <>
      <div className="xxs:w-11/12 lg:w-10/12 lg:my-32 lg:ml-0 lg:mr-auto xxs:my-12 xxs:mx-auto flex lg:gap-8 xl:gap-2 xl:flex-row xxs:flex-col items-end #">
        <div className="lg:w-[85%] xl:w-[55%] h-full">
          <div className="about-section-background  w-full  md:h-[550px] h-full  xxs:pt-0  lg:pt-8 xl:pt-12 2xl:pt-9">
            <div className="w-full md:h-[560px] lg:ml-8 xl:ml-12 2xl:ml-10 ">
              <img
                src={AboutCompanyImage}
                alt="happyMan"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
        <div className=" lg:w-[85%] xl:w-[65%] h-auto relative xl:mt-0 xxs:mt-8 sm:mt-20">
          <div className="h-full flex flex-col justify-center items-start lg:w-11/12 xl:w-10/12 my-auto ml-auto mr-0 ">
            <h1 className="text-black font-lato  font-black xxs:text-2xl md:text-4xl  2xl:text-[40px] 2xl:leading-[48px]">
              About Orient Company
            </h1>
            <p className="text-start text-textLight font-lato md:leading-[27px] md:text-lg xxs:text-sm xxs:leading-[24px] font-medium">
              Orient Company  is committed
              to the consistent pursuit of excellence and continuous dedication
              in improving its business to provide clients with products,
              services that fully comply with their requirements.
            </p>
            <p className="text-start text-textLight font-lato md:leading-[27px] md:text-lg xxs:text-sm xxs:leading-[24px] font-medium">
              OC is a registered company for contracting and procurement with
              several government agencies, non-governmental organizations and
              major defense contractors working in war zone and NGO missions
              through most volatile of the world. It is a professional firm
              specialized in the fields of General Trading, Safety Tools &
              Materials, I.T. Solutions, Advertising, Interior Designing,
              Printing and Designing.
            </p>
            <p className="text-start text-textLight font-lato md:leading-[27px] md:text-lg xxs:text-sm xxs:leading-[24px] font-medium">
              With the backing of highly qualified and motivated team of
              professionals, we have been able to execute our projects in the
              lowest turnaround times. We are committed towards continual
              improvement in our business processes and to maintain a healthy
              growth rate based on years of experience, sound management and
              dedication, to achieve quality in our operations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
